// galleryComponent.js
export default () => ({
  showSlider: false,
  skip: 1,
  slideIndex: 1,
  atBeginning: false,
  atEnd: false,

  init() {
    // Any initialization logic can go here
  },

  next() {
    if (this.atEnd) {
      this.slideIndex = 1;
    } else {
      this.slideIndex++;
    }
    this.updateSlider();
  },

  prev() {
    if (this.atBeginning) {
      this.slideIndex = this.$refs.slider.children.length;
    } else {
      this.slideIndex--;
    }
    this.updateSlider();
  },

  updateSlider() {
    let slider = this.$refs.slider;
    let offset = slider.firstElementChild.getBoundingClientRect().width;
    slider.scrollTo({ left: offset * (this.slideIndex - 1), behavior: 'smooth' });
    this.checkSliderBounds();
  },

  checkSliderBounds() {
    let slider = this.$refs.slider;
    let slideCount = slider.children.length;
    this.atBeginning = this.slideIndex === 1;
    this.atEnd = this.slideIndex === slideCount;
  },

  focusableWhenVisible: {
    'x-intersect:enter'() {
      this.$el.removeAttribute('tabindex');
    },
    'x-intersect:leave'() {
      this.$el.setAttribute('tabindex', '-1');
    },
  },

  disableNextAndPreviousButtons: {
    'x-intersect:enter.threshold.05'() {
      let slideEls = this.$el.parentElement.children;
      if (slideEls[0] === this.$el) {
        this.atBeginning = true;
      } else if (slideEls[slideEls.length - 1] === this.$el) {
        this.atEnd = true;
      }
    },
    'x-intersect:leave.threshold.05'() {
      let slideEls = this.$el.parentElement.children;
      if (slideEls[0] === this.$el) {
        this.atBeginning = false;
      } else if (slideEls[slideEls.length - 1] === this.$el) {
        this.atEnd = false;
      }
    },
  },
});
