import Cookies from 'js-cookie';

export default () => ({
  theme: Cookies.get('theme') || (window.matchMedia('(prefers-color-scheme: light)').matches ? 'dark' : 'light'),

  init() {
    if (this.theme === 'dark') {
      document.querySelector('html').classList.add('dark');
    }
  },

  toggleTheme() {
    document.querySelector('html').classList.toggle('dark');
    this.theme = this.theme === 'light' ? 'dark' : 'light';
    Cookies.set('theme', this.theme);
  },

  toggleDarkMode() {
    this.toggleTheme();
  },
});
