import axios from 'axios';
import Tribute from 'tributejs';
import camelcaseKeys from 'camelcase-keys';
import snakecaseKeys from 'snakecase-keys';

export default (mentionUrl, objectPk, contentType) => ({
  editorTextArea: null,

  async init() {
    this.editorTextArea = this.$el.querySelector('#id_comment');

    const mentionTribute = await this.getMentionTribute();
    if (mentionTribute) {
      mentionTribute.attach(this.editorTextArea);
    }

    const emojiTribute = await this.getEmojiTribute();
    if (emojiTribute) {
      emojiTribute.attach(this.editorTextArea);
    }
  },

  async getMentionTribute() {
    const url = mentionUrl;
    let tribute;

    try {
      const params = {
        objectPk: objectPk,
        contentType: contentType,
      };

      const response = await axios.get(url, {
        params: snakecaseKeys(params, { deep: true }),
      });
      const data = camelcaseKeys(response.data, { deep: true });

      let values = [];
      for (const userValue of data.result) {
        values.push({
          key: userValue.userName,
          value: userValue.userName,
        });
      }

      tribute = new Tribute({
        values: values,
      });
    } catch (error) {
      console.error(error);
    }

    return tribute;
  },

  async getEmojiTribute() {
    const url = 'https://api.github.com/emojis';
    let tribute;

    try {
      const response = await axios.get(url);
      const data = camelcaseKeys(response.data, { deep: true });

      let values = [];
      for (const key in data) {
        const value = data[key];
        values.push({
          key: key,
          value: value,
        });
      }
      tribute = new Tribute({
        trigger: ':',
        values: values,
        menuItemTemplate: function (item) {
          return `<img src="${item.original.value}"/>&nbsp;<small>:${item.original.key}:</small>`;
        },
        selectTemplate: function (item) {
          return `:${item.original.key}:`;
        },
        menuItemLimit: 5,
      });
    } catch (error) {
      console.error(error);
    }

    return tribute;
  },
});
