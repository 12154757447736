// This is the scss entry file
import '../styles/index.scss';

import Alpine from 'alpinejs';
import focus from '@alpinejs/focus';
import intersect from '@alpinejs/intersect';

import themeComponent from '../components/theme-component';
import commentComponent from '../components/comment-component';
import sliderComponent from '../components/slider-component';
import menuComponent from '../components/menu-component';

window.Alpine = Alpine;

Alpine.plugin(focus);
Alpine.plugin(intersect);

Alpine.data('themeComponent', themeComponent);
Alpine.data('commentComponent', commentComponent);
Alpine.data('sliderComponent', sliderComponent);
Alpine.data('menuComponent', menuComponent);

Alpine.start();
